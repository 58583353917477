<template>
  <Modal
    label="Interests"
    submit_btn_text="Add Selected"
    @submit="addSelected"
    @close="close"
    @keydown.esc="close"
    medium
  >
    <template v-slot:content>
      <div class="-py-3">
        <div
          class="flex justify-between h-8 border border-steel-gray rounded z-12 pr-3 mb-3"
        >
          <input
            type="text"
            aria-label="search"
            class="border-0 w-full grow rounded focus:outline-transparent mr-3"
            placeholder="Search Interest Areas..."
            v-model="search"
          />
          <div class="h-full text-2xl flex-none items-center py-1">
            <font-awesome-icon icon="search" class="text-dark-purple m-auto" />
          </div>
        </div>
        <div class="border border-cool-gray mb-3 -px-3 -mx-3" />
        <div class="pill-scroll overscroll-auto overflow-y-auto h-48 bg-white">
          <div
            class="px-2 font-normal pb-1"
            v-for="(item, key) in filtered_items"
            :key="key"
          >
            <label :for="`lookupItem_${key}`" class="px-2">
              <input
                type="checkbox"
                :aria-label="'checkbox for ' + item.description"
                class="rounded disabled:bg-light-gray"
                :id="`lookupItem_${key}`"
                v-model="item.selected"
                @input="resetSearch"
                :disabled="disableUnchecked && !item.selected"
              />
              {{ item.description }}
            </label>
          </div>
        </div>
        <div class="border border-cool-gray mt-3 -mx-3 -px-3" />
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/modal/Modal";

export default {
  name: "ListLookupModal",
  components: { Modal },
  emits: ["addSelection", "closeModal"],
  props: {
    items: {
      type: Object,
      required: true,
    },
    maxSelect: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      search: null,
    };
  },
  methods: {
    resetSearch() {
      setTimeout(() => (this.search = null), 100);
    },
    addSelected() {
      this.$emit(
        "addSelection",
        this.filtered_items
          .filter((item) => item.selected)
          .map((item) => {
            delete item.selected;
            return item;
          })
      );
    },
    close() {
      this.filtered_items.map((item) => {
        delete item.selected;
        return item;
      });
      this.$emit("closeModal");
    },
  },
  computed: {
    disableUnchecked() {
      return this.maxSelect !== null
        ? this.items.filter((item) => item.selected).length >= this.maxSelect
        : false;
    },
    filtered_items() {
      return this.search
        ? this.items.filter((item) =>
            new RegExp(this.search, "i").test(item.description)
          )
        : this.items;
    },
  },
};
</script>
