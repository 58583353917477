<template>
  <div class="pb-4">
    <span class="req" v-if="validate">*</span> Indicates a required field
  </div>
  <error-banner
    v-if="showErrors"
    :messages="errorMsgs"
    :info="errorMsgs.length === 0"
    :warn="validating"
    :error="errorMsgs.length > 0"
    class="mb-5"
  >
    <template v-slot:title>
      <div
        :class="{
          'font-semibold text-lg': true,
          'mb-3 pb-1 border-b': 0 !== errorMsgs.length,
        }"
      >
        <div v-if="validating">Validating ...</div>
        <div v-else-if="errorMsgs.length > 0">Error in Profile Data</div>
        <div v-else>Profile Data Valid</div>
      </div>
    </template>
    <template v-slot:body>
      <div class="flex items-center justify-start" v-if="validating">
        <div><font-awesome-icon icon="spinner" spin /></div>
      </div>
    </template>
  </error-banner>
  <div class="grid gap-6">
    <div class="box-style" data-cy="content-contact">
      <div class="secondhead-text">Contact</div>
      <div class="subhead-text">
        This is your primary contact information at the University of St.
        Thomas. Please update any incorrect information at the
        <a
          href="https://www.stthomas.edu/registrar"
          class="anchor"
          target="_blank"
          rel="noopener noreferrer"
        >
          registrar website </a
        >.
      </div>
      <div class="grid grid-cols-2 grid-rows-1 pb-3">
        <div class="row-start-1">
          <div class="font-bold">
            {{ student.firstname }} {{ student.lastname }}
          </div>
        </div>
        <div class="row-start-2">
          <div>
            <a>{{ primaryEmail ? primaryEmail.email_address : "" }}</a>
          </div>
          <div v-if="primaryPhone">
            ({{ primaryPhone?.phone_number.area_code }})
            {{ primaryPhone?.phone_number.prefix }}-{{
              primaryPhone?.phone_number.line
            }}
          </div>
          <div v-else>No Primary Phone</div>
        </div>
        <div v-if="primaryAddress" class="row-start-2">
          <div data-cy="edit-student-address-l1">
            {{ primaryAddress.address_line_one }}
          </div>
          <div
            data-cy="edit-student-address-l2"
            v-if="primaryAddress.address_line_two !== null"
          >
            {{ primaryAddress.address_line_two }}
          </div>
          <div data-cy="edit-student-city-state-zip">
            {{ primaryAddress.city }},
            {{ lookup_states[primaryAddress.state] }}
            {{ primaryAddress.zip_code }}
          </div>
        </div>
      </div>
      <div class="profile_box">
        <div class="thirdhead-text">Do you have a local address?</div>
        <div class="pb-4">
          <label for="checkbox-address">
            <span class="px-2">
              <input
                id="checkbox-address"
                data-cy="primary-address-checkbox"
                type="checkbox"
                v-model="use_primary_address"
                @click="setAddress(!use_primary_address)"
              />
            </span>
            Use primary contact address
          </label>
        </div>
        <div class="pb-4">
          <p>
            Providing more details helps us build a better experience for you.
          </p>
          <p>
            The information you provide below will not change the information
            you have on file with the Registrar’s office.
          </p>
        </div>
        <div class="pb-2">
          <label for="textbox-address-1"
            ><span class="req" v-if="validate">*</span> Address 1</label
          >
        </div>
        <div class="pb-3">
          <input
            id="textbox-address-1"
            data-cy="edit-student-address-l1-textfield"
            type="text"
            :class="{
              'border-steel-gray rounded h-8 w-1/3': true,
              'bg-light-gray': use_primary_address,
            }"
            v-model="address.address_line_one"
            :disabled="use_primary_address"
          />
        </div>
        <div class="pb-2">
          <label for="textbox-address-2">Address 2</label>
        </div>
        <div class="pb-3">
          <input
            id="textbox-address-2"
            data-cy="edit-student-address-l2-textfield"
            type="text"
            :class="{
              'border-steel-gray rounded h-8 w-1/3': true,
              'bg-light-gray': use_primary_address,
            }"
            v-model="address.address_line_two"
            :disabled="use_primary_address"
          />
        </div>
        <div class="w-1/3">
          <div class="flex-initial w-3/6">
            <div class="pb-2">
              <label for="textbox-city"
                ><span class="req" v-if="validate">*</span> City</label
              >
            </div>
            <div class="pb-3">
              <input
                id="textbox-city"
                data-cy="edit-student-city-textfield"
                type="text"
                :class="{
                  'border-steel-gray rounded h-8 w-full': true,
                  'bg-light-gray': use_primary_address,
                }"
                v-model="address.city"
                :disabled="use_primary_address"
              />
            </div>
          </div>
          <div class="flex-initial w-1/6">
            <div class="pb-2">
              <label for="textbox-state"
                ><span class="req" v-if="validate">*</span> State</label
              >
            </div>
            <div class="pb-3">
              <select
                data-cy="edit-student-state-selection"
                id="textbox-state"
                name=""
                :class="{
                  'text-sm p-1 pl-2 border-steel-gray rounded h-8 w-full': true,
                  'bg-light-gray': use_primary_address,
                }"
                v-model="address.state"
                :disabled="use_primary_address"
              >
                <option
                  v-for="(state, state_id) in lookup_states"
                  :value="state_id"
                  :key="state_id"
                >
                  {{ state }}
                </option>
              </select>
            </div>
          </div>
          <div class="flex-initial w-2/6">
            <div class="pb-2">
              <label for="textbox-zipcode"
                ><span class="req" v-if="validate">*</span> Zip</label
              >
            </div>
            <div class="pb-3">
              <input
                data-cy="edit-student-zip-textfield"
                id="textbox-zipcode"
                type="text"
                :class="{
                  'border-steel-gray rounded h-8 w-full': true,
                  'bg-light-gray': use_primary_address,
                }"
                v-model="address.zip_code"
                :disabled="use_primary_address"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="profile_box">
        <div class="pb-2">
          <div><span class="req" v-if="validate">*</span> Phone</div>
        </div>
        <div class="pb-3">
          <input
            aria-label="area code for phone number"
            data-cy="edit-student-areacode-textfield"
            type="text"
            maxlength="3"
            v-model="phone.phone_number.area_code"
            class="border-steel-gray rounded h-8 w-16 mr-2"
          />
          <input
            aria-label="first three digits for phone number"
            data-cy="edit-student-prefix-textfield"
            type="text"
            maxlength="3"
            v-model="phone.phone_number.prefix"
            class="border-steel-gray rounded h-8 w-16 mr-2"
          />
          <input
            aria-label="last four digits for phone number"
            data-cy="edit-student-line-textfield"
            type="text"
            maxLength="4"
            v-model="phone.phone_number.line"
            class="border-steel-gray rounded h-8 w-20"
          />
        </div>
      </div>
      <div class="profile_box">
        <div class="pb-2">
          <label
            ><span class="req" v-if="validate">*</span> Transportation</label
          >
        </div>
        <div class="pb-2">
          Do you have access to a vehicle to travel to and from Mentor Program
          activities?
        </div>
        <div class="pb-3">
          <input
            id="checkbox-no-transpotation"
            data-cy="edit-student-notransport-checkbox"
            type="checkbox"
            class=""
            value="NONE"
            v-model="transportation"
          />
          <label for="checkbox-no-transpotation"
            >I don't have transportation</label
          >
        </div>
        <div class="pb-2">I have access to:</div>
        <div
          class="pb-3"
          v-if="typeof transportation !== 'boolean' || transportation === false"
        >
          <div
            v-for="transport in transportation_options"
            :key="transport.value"
          >
            <input
              :data-cy="`edit-student-${transport.data_cy}`"
              :name="transport.name"
              :aria-label="'transportation ' + transport.name"
              :type="transport.type"
              :class="transport.class"
              :value="transport.value"
              v-model="transportation"
            />
            {{
              transport.value === "TRANSIT"
                ? "Public Transportation"
                : transport.value.charAt(0) +
                  transport.value.slice(1).toLowerCase()
            }}
            <div class="inline-block flex items-start py-1">
              <input
                aria-label="other for of transportation"
                type="text"
                class="h-8 rounded border-steel-gray"
                v-if="transportation === 'OTHER' && transport.value === 'OTHER'"
                v-model="transportation_other"
              />
            </div>
          </div>
        </div>
        <div
          class="pb-3"
          v-if="typeof transportation === 'boolean' && transportation === true"
        >
          No transportation available
        </div>
      </div>
    </div>
    <div v-if="this.$store.state.summer || isAdm()" class="box-style" data-cy="content-my-program-details">
      <div class="secondhead-text">My Program Details</div>
      <div class="subhead-text">
        Provide areas of interest, indicate preferences, and tell us what to
        consider so we can find your best-fit mentor.
      </div>
      <div class="profile_box">
        <div class="thirdhead-text">
          <span class="req" v-if="validate">*</span> Interests
        </div>
        <div class="pb-3">
          Please add up to five interest areas and then drag and drop your
          interest areas to prioritize them, with your most preferred interest
          area at the top.
        </div>
        <div class="pb-3">
          <div class="w-2/3">
            <draggable-list
              v-model="interests"
              @remove="removeInterest"
              list_of="interests"
              identifier="practice_area_id"
              data-cy="interests-list"
            />
            <div class="flex justify-start my-2">
              <div>
                <button
                  class="py-1 cursor-pointer px-2.5 bg-purple disabled:bg-light-purple hover:bg-dark-purple rounded-full text-white text-sm"
                  @click="show_interest_modal = !show_interest_modal"
                  :disabled="interests.length > 4"
                  data-cy="add-interest-button"
                >
                  Add Interests
                </button>
              </div>
              <ListLookupModal
                v-if="show_interest_modal"
                class="w-1/2 left-1/4"
                :items="filtered_lookup_practice_areas"
                :max-select="5 - interests.length"
                @addSelection="pushInterestSelection"
                @closeModal="show_interest_modal = !show_interest_modal"
                data-cy="list-lookup-modal-interests"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="profile_box">
        <div class="thirdhead-text">
          <!-- <span class="req" v-if="validate">*</span> -->
          Pairing Preferences
        </div>
        <div class="pb-4">
          Please select your top three most important factors we should consider
          when pairing you with a mentor, and then drag and drop your
          preferences into order to prioritize them, with your most important
          factor at the top.
        </div>
        <div class="w-2/3">
          <draggable-list
            v-model="preferences"
            @remove="removePairingPreference"
            list_of="preferences"
            identifier="pairing_factor_id"
            data-cy="preferences-list"
          />
          <div class="flex justify-start my-2">
            <div>
              <button
                class="py-1 cursor-pointer px-2.5 bg-purple disabled:bg-light-purple hover:bg-dark-purple rounded-full text-white text-sm"
                @click="show_preference_modal = !show_preference_modal"
                :disabled="preferences.length > 2"
                data-cy="add-preference-button"
              >
                Add Preferences
              </button>
            </div>
            <ListLookupModal
              v-if="show_preference_modal"
              class="w-1/4 z-10 left-1/4"
              :items="filtered_lookup_pairing_factors"
              :max-select="3 - preferences.length"
              @addSelection="pushPreferenceSelection"
              @closeModal="show_preference_modal = !show_preference_modal"
              data-cy="list-lookup-modal-preferences"
            />
          </div>
        </div>
      </div>
      <div class="profile_box">
        <label for="textarea-otherConsiderations" class="thirdhead-text"
          >Other Considerations</label
        >
        <div class="pb-4">
          Tell us any additional information that you'd like us to take into
          consideration when pairing you with a mentor.
        </div>
        <div class="w-2/3">
          <textarea
            id="textarea-otherConsiderations"
            data-cy="edit-pairing-pref-textfield"
            class="text-sm p-1 pl-2 border-steel-gray rounded h-24 w-full"
            v-model="other_considerations"
          >
          </textarea>
        </div>
      </div>
    </div>

    <div class="box-style" data-cy="content-about-me">
      <div class="secondhead-text">About Me</div>
      <div class="subhead-text">
        The questions below are optional to allow you to provide any additional
        information you would like us to consider in pairing you with your
        mentor.
      </div>
      <!-- preferred_name -->
      <div class="profile_box">
        <label for="textbox-preferredname" class="thirdhead-text"
          >Preferred Name</label
        >
        <div class="pb-2">Enter the name that you prefer to go by.</div>
        <input
          id="textbox-preferredname"
          type="text"
          data-cy="edit-preferredname-textfield"
          class="border-steel-gray rounded h-8 w-1/3"
          v-model="preferred_name"
        />
      </div>
      <!-- ethnicity -->
      <div class="profile_box">
        <div class="thirdhead-text">Ethnicity</div>
        <div class="pb-2">How would you classify yourself?</div>
        <div class="pb-3">
          <div
            v-for="(value, idx) in lookup_ethnicity"
            :key="`ethnicity_${idx}`"
          >
            <label>
              <input
                :id="`ethnicity_${idx}`"
                :data-cy="`ethnicity_${idx}`"
                name="ethnicity-check"
                :aria-label="'checkbox for race that applies ' + value.races"
                type="checkbox"
                class="my-1 mr-1"
                :value="value"
                v-model="ethnicity"
              />
              {{ value.race }}
            </label>
          </div>
          <label for="textbox-addotherethnicity">
            <input
              id="textbox-addotherethnicity"
              name="ethnicity-check"
              type="checkbox"
              class="my-1 mr-1"
              v-model="included_other_ethnicity"
              data-cy="edit-student-other-ethnicity"
            />
            Add another ethnicity
          </label>
          <div v-if="included_other_ethnicity" class="pt-2">
            <input
              type="text"
              aria-label="other ethnicity"
              class="border-steel-gray rounded h-8 w-1/3"
              v-model="ethnicity_custom.race"
              placeholder="Add another ethnicity"
              data-cy="edit-student-other-ethnicity-textfield"
            />
          </div>
        </div>
      </div>
      <!-- gender -->
      <div class="profile_box">
        <div class="thirdhead-text">Gender</div>
        <div class="pb-2">
          Which of the following most accurately describes you?
        </div>
        <!--          <div class="pb-2">Choose as many as you like.</div>-->
        <div class="pb-3">
          <ul>
            <li
              v-for="(gender_option, idx) in lookup_gender_options"
              :key="`gender_${idx}`"
            >
              <label :for="`gender_${idx}`">
                <input
                  :id="`gender_${idx}`"
                  :data-cy="`gender_${idx}`"
                  name="gender_option"
                  type="radio"
                  class="my-1 mr-1"
                  :value="gender_option"
                  v-model="gender"
                />
                {{ gender_option }}
              </label>
            </li>
          </ul>
          <div v-if="gender === 'Add another gender'" class="pt-2">
            <input
              type="text"
              aria-label="add another gender"
              class="border-steel-gray rounded h-8 w-1/3"
              v-model="gender_custom"
              placeholder="Add another gender"
              data-cy="edit-student-other-gener-textfield"
            />
          </div>
        </div>
      </div>
      <!-- pronouns -->
      <div class="profile_box">
        <div class="thirdhead-text">What are your pronouns?</div>
        <!--          <div class="pb-2">Check one or more options.</div>-->
        <div class="pb-3">
          <ul>
            <li
              v-for="(pronouns_option, idx) in lookup_pronouns_options"
              :key="`pronouns_${idx}`"
            >
              <label :for="`pronouns_${idx}`">
                <input
                  :id="`pronouns_${idx}`"
                  :data-cy="`pronouns_${idx}`"
                  name="pronoun_option"
                  type="radio"
                  class="my-1 mr-1"
                  :value="pronouns_option"
                  v-model="pronouns"
                />
                {{ pronouns_option }}
              </label>
            </li>
          </ul>
          <div v-if="pronouns === 'Add another pronoun set'" class="pt-2">
            <input
              type="text"
              aria-label="add another pronoun"
              class="border-steel-gray rounded h-8 w-1/3"
              v-model="pronouns_custom"
              placeholder="Add another pronoun set"
              data-cy="edit-student-other-pronouns-textfield"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="box-style" data-cy="content-education">
      <div class="secondhead-text">Education</div>
      <div class="subhead-text">
        Share with us your post-secondary education information
      </div>
      <div v-for="(edu, idx) in education" :key="idx" class="profile_box">
        <label for="textbox-school" class="thirdhead-text pb-1 block"
          >School</label
        >
        <input
          id="textbox-school"
          :data-cy="`edit-school-textfield-${idx}`"
          type="text"
          class="border-steel-gray rounded h-8 w-1/3 block"
          v-model="edu.school"
        />
        <label class="pb-3 block">Eg. University of St. Thomas</label>

        <label for="textbox-degree" class="thirdhead-text pb-1 block"
          >Degree</label
        >
        <input
          :data-cy="`edit-degree-textfield-${idx}`"
          type="text"
          id="textbox-degree"
          class="border-steel-gray rounded h-8 w-1/3 block"
          v-model="edu.degree"
        />
        <label class="pb-3 block">Eg. Bachelor's</label>

        <label for="textbox-fieldofstudy" class="thirdhead-text pb-1 block"
          >Field of study</label
        >
        <input
          id="textbox-fieldofstudy"
          :data-cy="`edit-fos-textfield-${idx}`"
          type="text"
          class="border-steel-gray rounded h-8 w-1/3 block"
          v-model="edu.field_of_study"
        />
        <label class="pb-3 block">Eg. Business</label>

        <label for="textbox-graduationYear" class="thirdhead-text pb-1 block"
          >Graduation year (or expected)</label
        >
        <input
          id="textbox-graduationYear"
          :data-cy="`edit-gradyear-textfield-${idx}`"
          type="text"
          class="border-steel-gray rounded h-8 w-20 block"
          v-model="edu.graduation_year"
        />
        <div class="pt-2 block">
          <button
            :data-cy="`edit-removeschool-button-${idx}`"
            class="inline-block py-1 mr-4 px-2.5 bg-purple hover:bg-dark-purple rounded-full text-white text-sm"
            @click="removeEducation(idx)"
          >
            Remove
          </button>
        </div>
      </div>
      <div class="mt-6 border-t border-cool-gray">
        <div class="pt-5">
          <button
            data-cy="edit-addschool-button"
            class="inline-block py-2 mr-4 px-4 bg-purple hover:bg-dark-purple rounded-full text-white"
            @click="addEducation"
          >
            Add school
          </button>
        </div>
      </div>
    </div>
    <!-- education -->
    <div class="relative h-14">
      <div class="absolute bottom-0 right-0">
        <button
          class="inline-block mr-6 py-3 px-9 bg-white border-purple border rounded-full text-dark-purple hover:text-white hover:bg-purple"
          @click="$emit('cancel')"
          data-cy="edit-student-cancel-button"
        >
          Cancel
        </button>
        <button
          data-cy="edit-student-save-button"
          class="inline-block py-3 px-9 bg-purple hover:bg-dark-purple rounded-full text-white"
          @click="updateStudentProfile"
        >
          <span class="pr-2" v-if="saving" data-cy="save_profile">
            <font-awesome-icon icon="spinner" spin />
          </span>
          <span v-if="errorMsgs.length !== 0 && showErrors">See Errors</span>
          <span v-else>Save</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorBanner from "@/components/forms/ErrorBanner";
import { DateTime } from "@/composables/date_utils";
import ListLookupModal from "@/components/lookup/ListLookupModal";
import { mapGetters } from "vuex";
import DraggableList from "@/components/draggable/DraggableList";
import {isAdm} from "@/composables/tokenUtils";

export default {
  components: {
    ErrorBanner,
    DraggableList,
    ListLookupModal,
  },
  name: "AddEditStudent",
  props: {
    student: {
      type: Object,
      require: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    acad_level: {
      type: Object,
    },
    validate: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: function () {
    return {
      // form data
      validating: false,
      valid: false,
      showErrors: false,
      errorMsgs: [],

      // form control data
      use_primary_address: false,
      show_interest_modal: false,
      show_preference_modal: false,
      included_other_ethnicity: false,
      transportation_options: [
        {
          name: "trans-radio",
          data_cy: "bicycle-radio",
          type: "radio",
          class: "mb-2",
          value: "BICYCLE",
          label: "Bicycle",
        },
        {
          name: "trans-radio",
          data_cy: "vehicle-radio",
          type: "radio",
          class: "mb-2",
          value: "VEHICLE",
          label: "Vehicle",
        },
        {
          name: "trans-radio",
          data_cy: "public-radio",
          type: "radio",
          class: "mb-2",
          value: "TRANSIT",
          label: "Public Transportation",
        },
        {
          name: "trans-radio",
          data_cy: "other-radio",
          type: "radio",
          class: "",
          value: "OTHER",
          label: "Other",
        },
      ],
      maxPreferences: false,

      // student data
      suffix: "",
      address: {
        address_line_one: "",
        address_line_two: "",
        city: "",
        state: "",
        zip: "",
        address_id: null,
        address_type: "STUDENT_LOCAL",
        from_date: null,
        to_date: null,
        is_primary: false,
        is_public: true,
      },
      phone: {
        phone_id: null,
        phone_type: "STUDENT_LOCAL",
        phone_number: {
          area_code: null,
          prefix: null,
          line: null,
        },
        added: null,
        updated: null,
        deleted: null,
        is_public: true,
        is_primary: true,
      },
      transportation: "",
      transportation_other: "",
      interests: [],
      preferences: [],
      other_considerations: "",
      prefix: null,
      gender: null,
      gender_custom: null,
      preferred_name: "",
      pronouns: "",
      pronouns_custom: "",
      ethnicity: [],
      education: [],
      ethnicity_custom: { race: null, description: "", display: false },
    };
  },
  mounted() {
    this.copyStudentData();
    this.$watch(
      (vm) => [
        vm.address,
        vm.phone,
        vm.transportation,
        vm.transportation_other,
        vm.interests,
        vm.preferences,
      ],
      () => {
        this.validateData(this.buildStudentDataToSave());
      },
      { immediate: false, deep: true }
    );
  },
  watch: {
    localStudent: {
      handler() {
        this.copyStudentData();
      },
      deep: true,
    },
  },
  methods: {
    isAdm,
    copyStudentData() {
      // console.log("copyStudentData")
      this.education =
        this.student?.education.length > 0 ? [...this.student.education] : [];

      let localAddress = this.student?.address.find(
        (address) => address.address_type === "STUDENT_LOCAL"
      );
      localAddress = localAddress ? { ...localAddress } : false;
      if (localAddress) {
        this.address = localAddress;
        this.use_primary_address = localAddress.is_primary;
      } else {
        this.setAddress(false);
      }

      let phone = this.student?.phone.find(
        (number) => number.phone_type === "STUDENT_LOCAL"
      );
      phone = phone ? { ...phone } : false;
      if (phone) {
        if (typeof phone.phone_number !== "object") {
          const phone_number = phone.phone_number.split("-");
          phone.phone_number = {
            area_code: phone_number[0],
            prefix: phone_number[1],
            line: phone_number[2],
          };
        }
        this.phone = { ...phone };
      }

      this.preferred_name = this.student?.nickname;
      this.other_considerations = this.student_profile?.pairing_considerations;
      this.transportation =
        this.student_profile?.vehicle_access === "NONE"
          ? true
          : this.student_profile.vehicle_access;

      this.interests = [...this.student_profile?.interest_areas].sort(
        (a, b) => a.priority - b.priority
      );
      this.preferences = [...this.student_profile?.pairing_factors].sort(
        (a, b) => a.priority - b.priority
      );
      this.gender = this.student?.gender;
      if (
        this.student?.gender &&
        !this.lookup_gender_options.includes(this.student?.gender)
      ) {
        this.gender = "Add another gender";
        this.gender_custom = this.student?.gender;
      }
      this.pronouns = this.student?.pronouns;
      if (
        this.student?.pronouns &&
        !this.lookup_pronouns_options.includes(this.student?.pronouns)
      ) {
        this.pronouns = "Add another pronoun set";
        this.pronouns_custom = this.student?.pronouns;
      }
      this.prefix = this.student?.prefix;
      this.student?.ethnicity.forEach((eth) => {
        if (
          this.lookup_ethnicity.findIndex(
            (e) => e.ethnicity_id === eth.ethnicity_id
          ) >= 0
        ) {
          this.ethnicity.push(eth);
        } else {
          this.included_other_ethnicity = true;
          this.ethnicity_custom = eth;
        }
      });
    },
    setAddress(primary = false) {
      if (primary === true) {
        this.address = { ...this.primaryAddress };
        this.address.address_id = null;
        this.address.address_type = "STUDENT_LOCAL";
      } else {
        this.address = {
          address_line_one: "",
          address_line_two: "",
          city: "",
          state: "",
          zip: "",
          address_id: null,
          address_type: "STUDENT_LOCAL",
          from_date: DateTime(),
          to_date: null,
          is_primary: false,
          is_public: true,
        };
      }
    },
    addEducation: function () {
      this.education.push({
        school: "",
        degree: "",
        field_of_study: "",
        graduation_year: "",
      });
    },
    removeEducation: function (index) {
      this.education.splice(index, 1);
    },
    updateStudentProfile() {
      this.validateData(this.buildStudentDataToSave()); // May not need this call
      this.valid
        ? this.$emit("submit", this.buildStudentDataToSave())
        : (this.showErrors = true);
    },
    validateData(studentData) {
      this.valid = true;
      if (this.validate) {
        this.validating = true;
        studentData = studentData ?? this.buildStudentData();
        this.errorMsgs = [];
        // Need to have a local address
        let local_address = studentData.address.find(
          (address) =>
            address.address_type === "STUDENT_LOCAL"
        );

        if (!local_address) {
          this.errorMsgs.push("Need a Local Address");
          this.valid = false;
        }
        studentData.address.forEach((address) => {
          if (address.address_type !== "STUDENT_LOCAL") {
            return;
          }
          if (!address.address_line_one) {
            this.errorMsgs.push("Address 1 cannot be blank");
            this.valid = false;
          }
          if (!address.city) {
            this.errorMsgs.push("Address's City cannot be blank");
            this.valid = false;
          }
          if (!address.state) {
            this.errorMsgs.push("Address's State cannot be blank");
            this.valid = false;
          }
          if (!address.zip_code) {
            this.errorMsgs.push("Address's Zip Code cannot be blank");
            this.valid = false;
          }
        });

        if (studentData.phone.length === 0) {
          this.errorMsgs.push("Need a Local Phone");
          this.valid = false;
        }

        studentData.phone.forEach((phone) => {
          if (!phone.phone_number.match(/((\d{3})-){2}(\d{4})/g)) {
            this.errorMsgs.push(
              "Phone Number specified is incomplete or malformed"
            );
            this.valid = false;
          }
        });

        if (!studentData.profiles[0].vehicle_access) {
          this.errorMsgs.push("Transportation access cannot be blank");
          this.valid = false;
        } else if (
          studentData.profiles[0].vehicle_access === "OTHER" &&
          !studentData.profiles[0].vehicle_access_other
        ) {
          this.errorMsgs.push("Specify the Other transportation type");
          this.valid = false;
        }

        if (studentData.profiles[0].interest_areas.length === 0) {
          this.errorMsgs.push(
            "A selection of more than one (1) and up to five (5) interest areas is required"
          );
          this.valid = false;
        }

        // if(studentData.profiles[0].pairing_factors.length !== 3) {
        //   this.errorMsgs.push("A selection of three (3) Pairing Preferences is required")
        //   valid=false
        // }
        // console.log("Valid", valid)
        this.validating = false;
      }
    },
    buildStudentDataToSave() {
      // console.log("buildStudentDataToSave")
      let addresses = [];
      if (this.primaryAddress) {
        addresses.push({ ...this.primaryAddress });
      }
      if (this.address.address_line_one?.trim()) {
        addresses.push(this.address);
      }
      addresses = addresses.map((address) => {
        address.person_id = this.student.student_id;
        return address;
      });

      let phones = [];

      if (this.primaryPhone) {
        phones.push({ ...this.primaryPhone });
      }

      let localPhone = { ...this.phone };
      if (
        localPhone.phone_number.area_code &&
        localPhone.phone_number.prefix &&
        localPhone.phone_number.line
      ) {
        localPhone.is_primary = false;
        localPhone.phone_type = "STUDENT_LOCAL";
        phones.push(localPhone);
      }

      phones = phones.map((phone) => {
        phone.person_id = this.student.student_id;
        phone.phone_number = `${phone.phone_number.area_code}-${phone.phone_number.prefix}-${phone.phone_number.line}`;
        phone.deleted = null;
        return phone;
      });

      const emails = [...this.student.email].map((email) => {
        email.person_id = this.student.student_id;
        email.deleted = null;
        return email;
      });

      const education = [...this.education].map((edu) => {
        edu.person_id = this.student.student_id;
        return edu;
      });

      const ethnicity = this.ethnicity;
      if (this.included_other_ethnicity) {
        ethnicity.push(this.ethnicity_custom);
      }

      const profile = { ...this.student_profile };
      profile.pairing_factors = Object.values(this.preferences)
        .flat()
        .filter((pf) => pf.selected);
      profile.interest_areas = this.interests.map((interest, order) => {
        interest.priority = order;
        return interest;
      });

      profile.pairing_factors = this.preferences.map((preference, order) => {
        preference.priority = order;
        return preference;
      });
      profile.pairing_considerations = this.other_considerations;
      profile.vehicle_access =
        this.transportation === true ? "NONE" : this.transportation;
      profile.vehicle_access_other =
        this.transportation === "OTHER" ? this.transportation_other : null;

      return {
        student_id: this.student.student_id,
        address: addresses,
        phone: phones,
        email: emails,
        firstname: this.student.firstname,
        lastname: this.student.lastname,
        nickname: this.preferred_name,
        prefix: this.prefix,
        suffix: this.student.suffix,
        student_status: "ACTIVE",
        pronouns:
          this.pronouns === "Add another pronoun set"
            ? this.pronouns_custom
            : this.pronouns,
        gender:
          this.gender === "Add another gender"
            ? this.gender_custom
            : this.gender,
        ethnicity: ethnicity,
        student_admit_term: this.student.student_admit_term,
        student_completion_term: this.student.student_completion_term,
        added: this.student.added,
        updated: this.student.updated,
        last_active_date: DateTime(),
        deleted: null,
        profiles: [profile],
        education: education,
      };

    },
    pushInterestSelection: function (selection) {
      this.interests = [...this.interests, ...selection];
      this.show_interest_modal = !this.show_interest_modal;
    },
    pushPreferenceSelection: function (selection) {
      this.preferences = [...this.preferences, ...selection];
      this.show_preference_modal = !this.show_preference_modal;
    },
    removeInterest: function (index) {
      this.interests.splice(index, 1);
    },
    removePairingPreference: function (index) {
      this.preferences.splice(index, 1);
    },
  },
  computed: {
    primaryAddress() {
      return this.student.address.find(
        (address) =>
          address.is_primary && address.address_type !== "STUDENT_LOCAL"
      );
    },
    primaryEmail() {
      return this.student?.email.find((email) => email.is_primary);
    },
    primaryPhone() {
      const phone = this.student?.phone.find((number) => number.is_primary);
      if (phone) {
        if (typeof phone.phone_number !== "object") {
          const phone_number = phone.phone_number.split("-");
          phone.phone_number = {
            area_code: phone_number[0],
            prefix: phone_number[1],
            line: phone_number[2],
          };
        }
        return phone;
      } else {
        return false;
      }
    },
    student_profile() {
      // console.log("student_profile");
      return this.student.profiles.length === 1
        ? this.student.profiles[0]
        : this.student.profiles.find(
            (profile) =>
              profile.student_at_level_id === this.acad_level.student_at_level_id
          );
    },
    localEmail() {
      return this.student?.email.find(
        (email) => email.email_type === "STUDENT_LOCAL"
      );
    },
    filtered_lookup_practice_areas() {
      return this.lookup_practice_areas
        .filter(
          (pa) =>
            !this.interests
              .map((i) => i.practice_area_id)
              .includes(pa.practice_area_id)
        )
        .sort((a, b) => {
          return a.description - b.description;
        });
    },
    filtered_lookup_pairing_factors() {
      return this.lookup_pairing_factors
        .filter(
          (pf) =>
            !this.preferences.map((i) => i.description).includes(pf.description)
        )
        .sort((a, b) => {
          return a.description.localeCompare(b.description);
        });
    },
    ...mapGetters([
      "lookup_states",
      "lookup_pairing_factors",
      "lookup_practice_areas",
      "lookup_ethnicity",
      "lookup_pronouns_options",
      "lookup_gender_options",
      "lookup_prefix_options",
    ]),
  },
};
</script>
